import type { DataLayerLocationsEnum } from './useDataLayer'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import type { PricingHeader } from '~/components/Pricing/PricingHeader.type'
import { DataLayerEventsEnum } from '~/types'
import { ISSUER_SECTION_URL } from '~/constants/common'

export enum SignUpModalType {
  default = 'default',
  premium = 'premium',
};

export function useSignUpIssue() {
  const dialogOpenedMap = useState('dialogOpenedMap', () => ({
    form: false,
    success: false,
    error: false,
  }))

  const isHowItWorksButtonVisible = useState<boolean>('showHowItWorksButton', () => false)

  const { trackEvent } = useDataLayer()

  const signUpEmail = useState<string | null>('signUpEmail', () => null)

  const formSendSuccess = useState<boolean | null>('signUpFormSuccess', () => null)
  const formType = useState<SignUpModalType | null>('type', () => null)
  const openedIssue = useState<IssueDetails | null>('issue', () => null)
  const resolvedSource = useState<DataLayerEventsEnum>('source', () => DataLayerEventsEnum.SrovIssuerFormSubmit)
  const resolvedEvent = useState<DataLayerEventsEnum>('event', () => DataLayerEventsEnum.SrovSignUpDone)
  const formOpenedFrom = useState<DataLayerLocationsEnum | null>('openedFrom', () => null)

  function openForm(signUpFormParams: {
    location: DataLayerLocationsEnum
    type: SignUpModalType
    showHowItWorksButton: boolean
    issue?: IssueDetails | null
    section?: string
    path: string
    tariff?: PricingHeader
  }) {
    if (signUpFormParams.type === SignUpModalType.default) {
      window.location.href = ISSUER_SECTION_URL
    }
    else {
      formType.value = signUpFormParams.type
      isHowItWorksButtonVisible.value = signUpFormParams.showHowItWorksButton

      resolvedSource.value = resolveSource()
      resolvedEvent.value = resolveEvent()
      dialogOpenedMap.value.form = true
      formOpenedFrom.value = signUpFormParams.location
      openedIssue.value = signUpFormParams.issue || null
    }

    trackEvent(
      signUpFormParams.type === SignUpModalType.premium ? DataLayerEventsEnum.SrovTariffClick : DataLayerEventsEnum.SrovSignUpStart,
      {
        location: signUpFormParams.location,
        issue: useCloneDeep(signUpFormParams.issue) || null,
        section: signUpFormParams.section,
        path: signUpFormParams.path,
        ...signUpFormParams.tariff && { tariff: useCloneDeep(signUpFormParams.tariff) },
      },
    )
  }

  function openSuccessModal() {
    dialogOpenedMap.value.success = true
  }

  function openErrorModal() {
    dialogOpenedMap.value.error = true
  }

  function resolveSource() {
    return formType.value === SignUpModalType.premium
      ? DataLayerEventsEnum.PremiumSrovIssuerFormSubmit
      : DataLayerEventsEnum.SrovIssuerFormSubmit
  }

  function resolveEvent() {
    return formType.value === SignUpModalType.premium
      ? DataLayerEventsEnum.SrovTariffSent
      : DataLayerEventsEnum.SrovSignUpDone
  }

  return {
    openForm,
    openSuccessModal,
    openErrorModal,
    isHowItWorksButtonVisible,
    signUpEmail,
    formSendSuccess,
    dialogOpenedMap,
    SignUpModalType,
    openedIssue,
    formType,
    resolvedSource,
    resolvedEvent,
    formOpenedFrom,
  }
}
